<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
        {{ $t('forms.add_model', {model: $t('models.licence')})}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmitLicence)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required|max:255"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              :label="$t('fields.full_name')"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required|max:100"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              :label="$t('fields.email_username')"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required|password|max:30"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              :label="$t('auth.password')"
              label-for="password"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  class="form-control-merge"
                  id="login-password"
                  name="login-password"
                  placeholder="Password"
                  v-model="userData.password"
                  :type="passwordFieldType"
                  :state="getValidationState(validationContext)"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <div class="invalid-feedback" :style="feedbackStyle($store.getters['app/currentBreakPoint'])">{{ validationContext.errors[0] }}</div>
            </b-form-group>
          </validation-provider>

          <!-- Max Children -->
          <validation-provider v-if="!resolveMaxChildrenByRole"
            #default="validationContext"
            :name="$t('max_children')"
            rules="required|integer|min:0"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              :label="$t('max_children')"
              label-for="max_children"
            >
              <b-form-input
                id="max_children"
                type="number"
                min="0"
                v-model="userData.max_children"
                :state="getValidationState(validationContext)"
                trim
                :disabled="resolveMaxChildrenByRole"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Language -->
          <validation-provider
            #default="validationContext"
            name="Language"
            rules="required"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              :label="$t('fields.lang')"
              label-for="lang"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.lang"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="langsOptions"
                :clearable="false"
                input-id="lang"
                label="name"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            :name="$t('user_role')"
            rules="required"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              :label="$t('user_role')"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- if role != Partner, mostrar payment_method -->
          <template v-if="userData.role && userData.role != 3">
            <!-- Plan -->
            <validation-provider
              #default="validationContext"
              name="Plan"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-form-group
                label="Plan"
                label-for="plan"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.currentPlan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="planOptions"
                  :selectable="val => val.can_select"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="plan"
                  :disabled="userData.role == 16"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- SUBSCRIPTION -->
            <b-form-group v-if="userData.role != 16">
              <b-form-checkbox
                v-model="userData.subsSelected"
                plain
              >
              {{ $t('free_subscription') }} 
              </b-form-checkbox>
            </b-form-group>

            <!-- SUBSCRIPTION DATES -->
            <template v-if="!userData.subsSelected">
              <!-- VALID_FROM -->
              <validation-provider
                #default="validationContext"
                name="Valid From"
                :rules="userData.role == 16?'required':''"
              >
              <b-form-group
                  label="Valid From"
                  label-for="valid_from"
                  :state="getValidationState(validationContext)"
                >
                <b-form-datepicker
                  id="valid_from"
                  v-model="userData.valid_from"
                  start-weekday="1"
                  class="mb-1"
                  :locale="getLocal"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>

              <!-- VALID_UNTIL -->
              <validation-provider
                #default="validationContext"
                name="Valid Until"
                :rules="userData.role == 16?'required':''"
                :custom-messages="{ required: $t('validators.required') }"
              >
              <b-form-group
                  label="Valid Until"
                  label-for="valid_until"
                  :state="getValidationState(validationContext)"
                >
                <b-form-datepicker
                  id="valid_until"
                  v-model="userData.valid_until"
                  class="mb-1"
                  start-weekday="1"
                  :min="minDate"
                  :locale="getLocal"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </template>

          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('forms.add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('forms.cancel')}}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { createLicence } from '@/api/routes'

import {
  BSidebar, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BInputGroup,
    BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules';
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ref } from '@vue/composition-api'
import { required, alphaNum, password, integer, min } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { decryptData } from '@/auth/utils'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker, 
    BInputGroup,
    BInputGroupAppend,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    langsOptions: {
      type: Array,
      required: true
    }
  },
  mixins: [togglePasswordVisibility],
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('max', {
      ...max,
      message: (field, { length }) => this.$t('forms.errors.max_characters', { length })
    });
    extend('password', {
      ...password,
      message: this.$t('forms.errors.password'),
    });
    extend('integer', {
      ...integer,
      message: this.$t('forms.errors.integer'),
    });
    extend('min', {
      ...min,
      message: (field, { length }) => this.$t('forms.errors.num_min', { length })
    });
  },
  data() {
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)


    return {
      required,
      alphaNum,
      password,

      minDate: tomorrow,

    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      password: '',
      role: null,
      currentPlan: null,
      lang: null,
      max_children: 5,
      subsSelected: true,
      valid_from: '',
      valid_until:''
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  computed:{
    resolveMaxChildrenByRole(){
      let loggedUser = decryptData(localStorage.getItem('userData'))
      if(loggedUser.role == "admin"){
        return false
      } else {
        return true
      }
    },
    getLocal(){
      return this.$store.getters['user/getLang']
    },
    selectedRole(){
      return this.userData.role
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch:{
    selectedRole(newValue){
      switch (newValue) {
        case 1: //admin
        case 12: //schol-admin
          break
        case 3: //partner
        case 14: // student
          this.userData.max_children = 1
          break;
        case 13: //teacher
          this.userData.max_children = 1
          break;
        case 16: //licencing
          this.userData.max_children = 1
          this.userData.currentPlan = 'Smile'
          this.userData.subsSelected = false
          break;
        default:
          this.userData.max_children = 5
          break;
      }
    }
  },
  methods:{
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    feedbackStyle(bkpoint){
      return{
        'display': 'block',
      }
    },
    validate(){
      if(!this.userData.subsSelected){
        if(this.userData.valid_from > this.userData.valid_until){
          this.makeToast('danger', this.$t('Error'), 'wrong date');
          return false
        }
      }
      return true
    },
    async onSubmitLicence(){
      let data = {
        context: "licence",
        currentPlan: this.userData.currentPlan,
        fullName:this.userData.fullName,
        lang: this.userData.lang.id,
        max_children: this.userData.max_children,
        password: this.userData.password,
        role: this.userData.role,
        subsSelected: this.userData.subsSelected,
        username: this.userData.username,
        valid_from: this.userData.valid_from,
        valid_until: this.userData.valid_until,

      }
      if(this.validate()){
        await this.$http.post(createLicence, data).then( response => {
          if(response.data.status == 200){
            this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
