/* REPORTS ROUTES */

let base_url = process.env.VUE_APP_DOMAIN_REPORTS;

if(base_url == 'http://127.0.0.1:8000' || base_url == 'http://localhost:8000' ){
  base_url='https://dev.services.smileandlearn.net'
}

// if (base_url == 'https://dev.services.smileandlearn.net') base_url = 'http://127.0.0.1:8002'

const micro_v = '/reports/v1'
const micro_v2 = '/reports/v2'

export const getLearningPathsForReports             = base_url + micro_v2 + '/get/learningPaths';
export const getContentsForReports                  = base_url + micro_v2 + '/get/contents';
export const sendDataToMakeReport                   = base_url + micro_v2 + '/get/create-pdf';
export const getDocumentsList                       = base_url + micro_v +  '/get/documents';
export const deleteDocument                         = base_url + micro_v +  '/delete/document';
export const createCatalog                          = base_url + micro_v2 +  '/catalogs/create';

export const getGroups                              = base_url + micro_v2 + '/get/groups';
export const getDreamers                            = base_url + micro_v2 + '/get/dreamers';
export const getContentsHierarchyForReports         = base_url + micro_v2 + '/get/contents/hierarchy';

