<template>
  
    <b-card-body>
      <b-row>
        <b-col v-if="countriesOptions.length>0"
          cols="12"
          md="3"
          class="mb-1"
        >
          <label>{{ $t('fields.country') }}</label>
          <v-select
            :options="countriesOptions"
            v-model="country_selected"
          />
        </b-col>
        <b-col v-if="planOptions.length>0"
          cols="12"
          md="3"
          class="mb-1"
        >
          <label>{{ $t('fields.payment_method_id') }}</label>
          <v-select
            :options="planOptions"
            v-model="plan_selected"
          />
        </b-col>
        <b-col v-if="roleOptions.length>0"
          cols="12"
          md="3"
          class="mb-1"
        >
          <label>{{ $t('fields.role') }}</label>
          <v-select
            :options="roleOptions"
            v-model="role_selected"
          />
        </b-col>        
        <b-col v-if="groupsOptions.length >0"
          cols="12"
          lg="4"          
          md="8"
          class="mb-1"
        >
          <label>{{ $t('fields.group') }}</label>
          <v-select
            :options="groupsOptions"
            v-model="group_selected"
          />
        </b-col>
        <b-col v-if="ageRangeOptions.length >0"
          cols="12"
          lg="2"          
          md="4"
          class="mb-1"
        >
          <label>{{ $t('fields.age_range') }}</label>
          <v-select
            :options="ageRangeOptions"
            v-model="age_range_selected"
          />
        </b-col>
        <b-col v-if="neeOptions.length >0"
          cols="12"
          md="6"
          class="mb-1"
        >
          <label>{{ $t('fields.nee') }}</label>
          <v-select
            :options="neeOptions"
            v-model="nee_selected"
          />
        </b-col>
        <b-col v-if="statusOptions.length > 0" 
          cols="12"
          md="3"
          class="mb-1"
        >
          <label>{{$t('fields.status')}}</label>
          <v-select
            :options="statusOptions"
            v-model="status_selected"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          lg="6"
          class="mb-1"
        >
          <label>{{ searchword_label }}</label>
          <b-form-input
            v-model="searchword"
            :placeholder="$t('filters.search')+'...'"
            v-on:keyup.enter="search"
            type="search"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <label></label>
          <b-button block
            @click="search">{{ $t('filters.search') }}</b-button>
        </b-col>
      </b-row>
    </b-card-body>
</template>


<script>
import { searchSchool, searchSchoolGroups, searchSchoolDreamers, searchSchoolTeachers, searchSchoolStudents, searchPartners, searchDreamers, searchLicences } from '@/api/routes'
import { getDreamers } from '@/api/reports'

import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BButton, BFormInput
} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
  name:"Filters",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BFormInput,
    vSelect,
  },
  props: {
    school_id:{
      type: [String, Number],
      required:false,
    },
    context:{
      type: String,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false,
      default() {
          return []
      }
    },
    planOptions: {
      type: Array,
      required: false,
      default() {
          return []
      }
    },
    countriesOptions: {
      type: Array,
      required: false,
      default() {
          return []
      }
    },
     statusOptions: {
      type: Array,
      required: false,
      default() {
          return []
      }
    }, 
    groupsOptions: {
      type: Array,
      required: false,
      default() {
          return []
      }
    }, 
    nee: {
      type: Array,
      required: false,
      default() {
          return []
      }
    }, 
    ageRangeOptions: {
      type: Array,
      required: false,
      default() {
          return []
      }
    }, 
    isReport: {
      type: Boolean,
      required: false,
      default: false,
    },
    user_id: {
      type: [Number, String],
      required: false,
      default: null,
    },
    start_date: {
      type: [Number, String],
      required: false,
      default: null,
    },
    end_date: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data(){
    return {
      role_selected: null,
      plan_selected: null,
      country_selected: null,
      status_selected: { value: 'active', label: this.$t('fields.active') },
      group_selected: null,
      nee_selected: null,
      age_range_selected: null,
      searchword: null,
    }
  },
  computed:{
    neeOptions(){
      let newNeeOptions = []
      this.nee.forEach(x => {
        let can_select = true;
        newNeeOptions.push(
            { 'value': x.id, 'label': this.$t('special_needs.'+x.name), 'can_select': can_select }
        ) 
      });
      return newNeeOptions;
    },
    searchword_label(){
      let msg = ''
      switch (this.context) {
        case 'user-dreamers':
        case 'groups':
        case 'dreamers':
          msg = this.$t('filters.search_by')+' '+this.$t('fields.name')
          break;
      
        default:
          msg = this.$t('filters.search_by_name_or_email')
          break;
      }
      return msg;
    }
  },
  methods: {
    async search(){
      this.$emit('set-loading-data', true);
      let data = {
        'token': this.$store.getters['user/getUserData'].sso,
        'school_id' : this.school_id,
        'country': this.country_selected ? this.country_selected.value : null,
        'plan': this.plan_selected ? this.plan_selected.value : null,
        'role': this.role_selected ? this.role_selected.value : null,
        'group': this.group_selected ? this.group_selected.value : null,
        'nee': this.nee_selected ? this.nee_selected.value : null,
        'age_range': this.age_range_selected ? this.age_range_selected.value:null,
        'status': this.status_selected ? this.status_selected.value : null,
        'searchword': this.searchword,
        'context': this.context
      }
      let route = ""
      if(this.isReport){
        data["user_id"] = this.user_id
        data["start_date"] = this.start_date
        data["end_date"] = this.end_date
        route = getDreamers;
      } else {
        switch (this.context) {
          case 'schools':
            route = searchSchool;
            break;
          case 'groups':
            route = searchSchoolGroups
            break;
          case 'dreamers':
            route = searchSchoolDreamers
            break;
          case 'teachers':
            route = searchSchoolTeachers
            break;
          case 'students':
            route = searchSchoolStudents
            break;  
          case 'partners':
            route = searchPartners
            break;  
          case 'user-dreamers':
            route = searchDreamers
            break;      
          default:
            route = searchLicences
            break;
        }
      }
      await this.$http.post(route, data).then( response => {
        if(response.data.status == 400 && response.data.msg_code != 'ERR_DREAMER_1001') {
          this.makeToast('danger', this.$t('Error'), response.data.msg ?? this.$t('error_retry_later'));
          this.$emit('set-loading-data', false);
        }
        this.$emit('set-loading-data', false);
        this.$emit('set-data', response.data);
      }).catch(error => {
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        this.$emit('set-loading-data', false);
      })
      
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
