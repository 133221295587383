<template>
  <div>
    <b-card-body v-if="canSelect || canDownload || buttonsOptions">
      <b-row>
        <b-col cols="6">
          <b-form-checkbox v-if="canSelect && data.length > 0" v-model="allSelected" @change="manageSelectAll(allSelected)"
            inline :disabled="checkboxIsDisabled">
            {{ $t('table.select_all_rows') }}
          </b-form-checkbox>
        </b-col>
        <b-col cols="6" style="text-align:right">
          <b-button v-if="canDownload" v-b-tooltip.hover.top="$t('table.export_to_excel')" variant="primary" type="button"
            class="ml-1 mb-1" @click="download" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
            <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path fill="#fff"
                d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z" />
            </svg>
          </b-button>
          <!-- buttonsOptions -->
          <template v-for="button in buttonsOptions">
            <b-button v-if="!button.dropdown" :key="button.action" v-b-tooltip.hover.top="button.name"
              :disabled="button.disabled"
              :variant="button.variant || 'primary'" type="button" class="ml-1 mb-1" @click="$emit(button.action)"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                <span v-if="button.msg">{{$t(button.msg)}}</span>
                <feather-icon v-else
                  :icon="button.icon"
                  size="16"
                />
            </b-button>
            <!-- dropdown -->
            <b-dropdown v-else right class="ml-1 mb-1" :key="button.action" :id="button.action"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="button.variant"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'" v-b-tooltip.hover.top="button.name">
              <template #button-content>
                <feather-icon :icon="button.icon" size="16" class="align-middle" />
              </template>
              <b-dropdown-item v-for="item in button.dropdown" :key="item.action" @click="$emit(item.action)"
                :variant="item.variant">
                <feather-icon :icon="item.icon" size="16" />
                <span class="align-middle ml-50">{{ item.name }}</span>
              </b-dropdown-item>
            </b-dropdown>
            <!-- fin dropdown -->
          </template>

        </b-col>
      </b-row>
    </b-card-body>

    <!-- TABLE  -->
    <b-skeleton-table v-if="loading_data" :rows="5" :columns="4"
      :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
    <b-table v-else 
      :per-page="perPage" 
      :fields="tableColumns" 
      :items="data" responsive 
      :current-page="currentPage" 
      :empty-text="fetch_message || $t('table.empty')" 
      :style="{ 'min-height': customMinHeight + 'px' }"
      @row-selected="onRowSelected" 
      class="position-relative custom-min-height"
      ref="refUserListTable" 
      select-mode="multi"
      primary-key="id" 
      sort-icon-left 
      show-empty
      striped 
      selectable 
      no-select-on-click
    >

      <!-- Column: MAIN -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <!-- !(link=='school-groups' && data.item.id == 0) &&  -->
            <b-form-checkbox v-if="canSelect && !data.item.deleted_at" class="mr-2" v-model="data.rowSelected"
              :disabled="data.item.deleted_at ? true : false"
              @change="manageRowSelection(data.index, data.rowSelected)"></b-form-checkbox>
            <div v-else-if="data.item.id == 0" class="mr-4"></div>
            <b-avatar :rounded="link == 'group' || link == 'school-groups'" size="32" :src="resolveAvatar(data.item)"
              :text="avatarText(data.item.name || data.item.description)"
              :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
              :to="{ name: link + '-view', params: link_params(data) }" />
          </template>
          <b-link v-if="(link == 'school-groups') && data.item.id == 0"
            :to="{ name: data.item.id == 0 ? link + '-view-no-group' : link + '-view', params: link_params(data) }"
            class="font-weight-bold d-block text-nowrap">
            {{ $t('table.no_group') }}
          </b-link>
          <b-link v-else :to="{ name: link + '-view', params: link_params(data) }"
            class="font-weight-bold d-block text-nowrap">
            {{ link == 'group' && data.item.id == 0 ? $t('table.no_group') : data.item.name || data.item.description }}
          </b-link>
          <!-- <small v-if="!(link == 'school-groups' && data.item.id == 0) && data.item.id != '0'" class="text-muted">ID# {{
            data.item.id }}</small><br> -->
          <small v-if="data.item.registration_number" class="text-muted">#{{
                data.item.registration_number }}</small>
        </b-media>
      </template>

      <template #cell(document_name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <!-- !(link=='school-groups' && data.item.id == 0) &&  -->
            <b-avatar size="32" :variant="data.item.type == 'ZIP' ? `light-primary` : `light-secondary`">
              <feather-icon :icon="data.item.type == 'ZIP' ? 'ArchiveIcon' : 'FileIcon'" />
            </b-avatar>
          </template>
          {{ data.item.name }}
        </b-media>
      </template>

      <!-- Column: owner -->
      <template #cell(owner)="data">
        <div class="text-center" v-if="data.item.id != 0">
          <b-badge pill :variant="data.item.owner ? 'success' : 'warning'">{{
            data.item.owner ? $t('dreamer.own') : $t('dreamer.shared') }}</b-badge>
        </div>
      </template>

      <!-- Column: Dreamers -->
      <template #cell(dreamers)="data">
        <b-avatar-group class="mt-2 pt-50 mb-1" size="33">
          <b-avatar v-for="dreamer in data.item.dreamers" :key="dreamer.id" v-b-tooltip.hover.bottom="dreamer.name"
            :src="require('@/assets/images/avatar/AvatarSprite_' + (dreamer.avatar || 57) + '.png?v4')" class="pull-up"
            variant="light" :to="{
              name: 'school-dreamers-view', params: {
                id: school_id,
                dreamer_id: dreamer.id
              }
            }" />
          <template v-if="data.item.dreamers.total_dreamer">
            <h6 class="align-self-center cursor-pointer ml-1 mb-0" v-if="data.item.dreamers.total_dreamer > 0">
              {{ data.item.dreamers.total_dreamer - 5 > 0 ? '+' + (data.item.dreamers.total_dreamer - 5) : '' }}
            </h6>
            <span v-else class="text-muted"> {{ $t('table.no_dreamers') }} </span>
          </template>
          <template v-else>
            <span v-if="data.item.dreamers.length == 0" class="text-muted"> {{ $t('table.no_dreamers') }} </span>
          </template>

        </b-avatar-group>
      </template>

            <!-- Column: Learning Paths -->

            <template #cell(learning_paths)="data">
              <div v-if="data.item.learnign_path" @click="$emit('mostrar-sidebar', data.item.id)" v-b-tooltip.hover.bottom="$t('see_more')" class="mt-2 mb-1 cursor-pointer rounded-circle d-flex justify-content-center align-items-center icon-container">
                <svg width="18px" height="18px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="#87bced" d="M507.31 84.69L464 41.37c-6-6-14.14-9.37-22.63-9.37H288V16c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v16H56c-13.25 0-24 10.75-24 24v80c0 13.25 10.75 24 24 24h385.37c8.49 0 16.62-3.37 22.63-9.37l43.31-43.31c6.25-6.26 6.25-16.38 0-22.63zM224 496c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V384h-64v112zm232-272H288v-32h-64v32H70.63c-8.49 0-16.62 3.37-22.63 9.37L4.69 276.69c-6.25 6.25-6.25 16.38 0 22.63L48 342.63c6 6 14.14 9.37 22.63 9.37H456c13.25 0 24-10.75 24-24v-80c0-13.25-10.75-24-24-24z"/></svg>
              </div> 
            </template>
          
            <!-- Column: PIN -->

            <template #cell(pin)="data">
                <b-avatar class="mt-2 mb-1" v-if="(data.item.pin && data.item.pin.length > 0) || (data.item.password && data.item.password.length > 0)" :rounded="link == 'group' || link == 'school-groups'" size="32"
                variant="primary"
                v-b-popover.hover.bottom.html="imageTooltip(data.item.pin || data.item.password)">
                <feather-icon icon="UnlockIcon"/>
                </b-avatar>
            </template>

      <!-- Column: linked -->
    <template #cell(linked)="data">
        <b-avatar-group class="mt-2 pt-50 mb-1" size="33">
            <b-avatar :rounded="link == 'dreamer'" v-for="l in data.item.linked.slice(0, 5)" :key="l.id" v-b-tooltip.hover.bottom="l.name"
                :src="link == 'group' ? require('@/assets/images/avatar/AvatarSprite_' + (l.avatar || 57) + '.png?v4') : require('@/assets/images/avatar/groups/' + (l.avatar || l.id_avatar || 'null') + '.png?v4')"
                class="pull-up" variant="light" :to="{
                    name: link == 'group' ? 'dreamer-view' : 'group-view', params: {
                        id: l.id
                    }
                }" />
            <h6 class="align-self-center cursor-pointer ml-1 mb-0" v-if="data.item.linked.length > 5">
                {{ '+' + (data.item.linked.length - 5) }}
            </h6>
            <template v-else>
                <span v-if="link == 'group' && data.item.linked.length === 0" class="text-muted"> {{ $t('table.no_dreamers') }} </span>
                <b-avatar :rounded="link == 'dreamer'" v-else-if="link == 'dreamer' && data.item.linked.length === 0" key="0" v-b-tooltip.hover.bottom="$t('table.no_group')"
                    :src="require('@/assets/images/avatar/groups/null.png?v4')" class="pull-up" variant="light"
                    :to="{ name: 'group-view', params: { id: 0 } }" />
            </template>
        </b-avatar-group>
    </template>

      <!-- Column: groups -->
      <template #cell(groups)="data">
        <b-avatar-group class="mt-2 pt-50 mb-1" size="33">
          <b-avatar rounded v-for="group in data.item.groups" :key="group.id" v-b-tooltip.hover.bottom="group.name"
            :src="require('@/assets/images/avatar/groups/' + (group.id_avatar || 'null') + '.png?v4')" class="pull-up"
            variant="light" :to="{
              name: 'school-groups-view', params: {
                id: school_id,
                group_id: group.id
              }
            }" />
          <h6 class="align-self-center cursor-pointer ml-1 mb-0" v-if="data.item.groups.length > 0">
            {{ data.item.groups.length - 5 > 0 ? '+' + (data.item.groups.length - 5) : '' }}
          </h6>


          <b-avatar v-else rounded key="0" v-b-tooltip.hover.bottom="$t('table.no_group')"
            :src="require('@/assets/images/avatar/groups/null.png?v4')" class="pull-up" variant="light" :to="{
              name: 'school-groups-view-no-group',
              params: params = {
                id: school_id,
                group_id: 0
              }
            }" />

        </b-avatar-group>
      </template>

      <!-- Column: birthdate -->
      <template #cell(birthdate)="data">
        <div class="text-nowrap">
          <span>{{ new Date(data.item.birthdate).toLocaleDateString() }}</span>
        </div>
      </template>

      <!-- Column: Created_at -->
      <template #cell(created_at)="data">
        <div class="text-nowrap">
          <span v-if="!(link == 'school-groups' && data.item.id == 0) && data.item.id != '0'">{{ new
            Date(data.item.created_at).toLocaleDateString() }}</span>
        </div>
      </template>

      <!-- Column: Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50"
            :class="`text-${resolveUserRoleVariant(data.item.role)}`" />
          <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge pill :variant="`light-${data.item.deleted_at ? 'danger' : 'success'}`" class="text-capitalize">
          {{ data.item.deleted_at ? $t('fields.inactive') : $t('fields.active') }}
        </b-badge>
      </template>
      <!-- Column: Subscription -->
      <template #cell(subscription)="data">
        <b-badge pill :variant="itemIsActiveColor(data.item.subscription)" class="text-capitalize">
          {{ $t(itemIsActive(data.item.subscription)) }}
        </b-badge>
      </template>
      <!-- Column: document_state -->
      <template #cell(document_state)="data">
        <b-badge pill :variant="`light-${data.item.status == 'KO' ? 'danger' : data.item.status == 'OK' ? 'success' : 'warning'}`"
          class="text-capitalize">
          {{ data.item.status == 'KO' ? $t('reports.documents.error') : data.item.status == 'OK' ?
            $t('reports.documents.completed') : $t('reports.documents.in_progress') }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>
          <b-dropdown-item
            :to="{ name: link == 'school-groups' && data.item.id == 0 ? link + '-view-no-group' : link + '-view', params: link_params(data) }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">{{ $t('table.details') }}</span>
          </b-dropdown-item>

          <template v-if="!(link == 'school-groups' && data.item.id == 0) && $can('update', ability)">
            <b-dropdown-item v-if="!data.item.deleted_at" :to="{ name: link + '-edit', params: link_params(data) }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('forms.edit') }}</span>
            </b-dropdown-item>
          </template>

          <template v-if="!(link == 'school-groups' && data.item.id == 0) && canDelete && $can('delete', ability)">
            <b-dropdown-item v-if="!data.item.deleted_at" @click="$emit('delete', data.item.id)">
              <feather-icon color="red" icon="TrashIcon" />
              <span style="color:red" class="align-middle ml-50">{{ $t('forms.delete') }}</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="canRecover && data.item.deleted_at" @click="$emit('recover', data.item.id)">
              <feather-icon icon="CornerUpLeftIcon" />
              <span class="align-middle ml-50">{{ $t('forms.recover') }}</span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>

      <!-- Column: CustomActions -->
      <template #cell(custom_actions)="data">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>
          <template v-if="link == 'group'">
            <!-- Añadir dreamer cuando el grupo es != 0 y propio -->
              <b-dropdown-item v-if="data.item.owner || data.item.id == 0" @click="$emit('add-dreamer', data.item)"
              :disabled="!can_create_new_dreamers">
                <feather-icon icon="UserPlusIcon" />
                <span class="align-middle ml-50">{{ $t('forms.add_model', { model: $t('models.dreamer') }) }}</span>
              </b-dropdown-item>
          </template>
          <!-- Acceder a los detalles del grupo -->
          <b-dropdown-item
            :to="{ name: link == 'school-groups' && data.item.id == 0 ? link + '-view-no-group' : link + '-view', params: link_params(data) }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">{{ $t('table.details') }}</span>
          </b-dropdown-item>

          <!-- <template v-if="!(link=='school-groups' && data.item.id == 0) && $can('update', ability) && (link=='group' && data.item.owner)">
              <b-dropdown-item v-if="!data.item.deleted_at" :to="{ name: link+'-edit', params: link_params(data) }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{$t('forms.edit')}}1</span>
              </b-dropdown-item>
            </template> -->

          <!-- Editar cuando es grupo != 0 y propio -->
          <template v-if="data.item.id != 0 && $can('update', ability) && (link == 'group' && data.item.owner)">
            <b-dropdown-item v-if="!data.item.deleted_at" :to="{ name: link + '-edit', params: link_params(data) }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('forms.edit') }}</span>
            </b-dropdown-item>
          </template>
          <!-- Compartir cuando es grupo propio -->
          <template v-if="link == 'group'  && data.item.owner">
            <template v-if="data.item.id != 0">
              <b-dropdown-item :to="{ name: link + '-edit', params: { id: data.item.id, tab: 1 } }">
                <feather-icon icon="Share2Icon" />
                <span class="align-middle ml-50">{{ $t('general.share') }}</span>
              </b-dropdown-item>
            </template>
          </template>
          <!-- Editar cuando es dreamer propio o compartido -->
          <template v-if="$can('update', ability) && (link == 'dreamer')">
            <b-dropdown-item v-if="!data.item.deleted_at" :to="{ name: link + '-edit', params: link_params(data) }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('forms.edit') }}</span>
            </b-dropdown-item>
          </template>
          <!-- Personalizar -->
          <b-dropdown-item @click="$emit('personalize', data.item)">
            <feather-icon icon="SettingsIcon" />
            <span class="align-middle ml-50">{{ $t('customize.customize') }}</span>
          </b-dropdown-item>
          <!-- Restricción de contenidos -->
          <b-dropdown-item @click="$emit('content-restriction', data.item)">
            <feather-icon icon="ClockIcon" />
            <span class="align-middle ml-50">{{ $t('customize.mandatory') }}</span>
          </b-dropdown-item>
          <!-- Gestionar contraseñas -->
          <template v-if="link == 'group'">
            <b-dropdown-item @click="$emit('passwords', data.item)">
              <feather-icon icon="UnlockIcon" />
              <span class="align-middle ml-50">{{ $t('settings.change_pass') }}</span>
            </b-dropdown-item>
          </template>
          <template v-else>
            <b-dropdown-item @click="$router.push({ name: 'dreamer-edit', params: { id: data.item.id, tab:1  } })">
              <feather-icon icon="UnlockIcon" />
              <span class="align-middle ml-50">{{ $t('settings.change_pass') }}</span>
            </b-dropdown-item>
          </template>
          <!-- Compartir cuando es dreamer propio -->
          <template v-if="link == 'dreamer' && data.item.owner">
            <b-dropdown-item :to="{ name: link + '-edit', params: { id: data.item.id, tab: 2 } }">
              <feather-icon icon="Share2Icon" />
              <span class="align-middle ml-50">{{ $t('general.share') }}</span>
            </b-dropdown-item>
          </template>
          <!-- Desvincular cuando es grupo compartido -->
          <template v-if="link == 'group' && !data.item.owner">
            <b-dropdown-item @click="$emit('unlinkSharedGroup', data.item.id)">
              <feather-icon color="red" icon="XIcon" />
              <span style="color:red" class="align-middle ml-50">{{ $t('dreamer.unlink_shared_group') }}</span>
            </b-dropdown-item>
          </template>
          <!-- Desvincular cuando es dreamer compartido -->
          <template v-if="link == 'dreamer' && !data.item.owner">
            <b-dropdown-item @click="$emit('unlinkSharedDreamer', data.item.id)">
              <feather-icon color="red" icon="XIcon" />
              <span style="color:red" class="align-middle ml-50">{{ $t('dreamer.unlink_shared_dreamer') }}</span>
            </b-dropdown-item>
          </template>
          <!-- Eliminar cuando es grupo != 0 y propio -->
          <template v-if="data.item.id != 0 && canDelete && $can('delete', ability) && (data.item.owner)">
            <b-dropdown-item style="color:red" v-if="!data.item.deleted_at" @click="$emit('delete', data.item.id)">
              <feather-icon color="red" icon="TrashIcon" />
              <span style="color:red" class="align-middle ml-50">{{ $t('forms.delete') }}</span>
            </b-dropdown-item>
            <!-- Recuperar cuando el grupo está eliminado -->
            <b-dropdown-item v-if="canRecover && data.item.deleted_at" @click="$emit('recover', data.item.id)">
              <feather-icon icon="CornerUpLeftIcon" />
              <span class="align-middle ml-50">{{ $t('forms.recover') }}</span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>

      <!-- Column: document_actions -->
      <template #cell(document_actions)="data">
        <a v-if="data.item.status == 'OK'" :href="data.item.aws_s3_url_public" :download="data.item.name" target="_blank"
          rel="noopener noreferrer">
          <b-button v-b-tooltip.hover.top="$t('reports.documents.download')" v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary" class="btn-icon rounded-circle ml-1 mb-1" @click="$emit('download', data.item.id)">
            <feather-icon icon="DownloadIcon" />
          </b-button>
        </a>
        <b-button v-if="data.item.status != 'pending'" v-b-tooltip.hover.top="$t('reports.documents.delete_document')"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle ml-1 mb-1"
          @click="$emit('delete', data.item)">
          <feather-icon icon="TrashIcon" />
        </b-button>

      </template>

    </b-table>

    <!-- Table Footer -->
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0" v-if="data.length > 0">
      <b-form-group :label="$t('table.items_shown_per_page')" label-cols="8" label-align="left" label-size="sm"
        label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
        <b-form-select id="perPageSelect" class="ml-1" v-model="perPage" size="sm" inline :options="perPageOptions" />
      </b-form-group>
      <div>
        <span class="text-muted">{{ $t('table.showing_entries', {
          from: dataMeta.from,
          to: dataMeta.to,
          of: dataMeta.of
        }) }}
        </span>
      </div>
      <div>
        <b-pagination v-model="currentPage" :total-rows="data.length" :per-page="perPage" first-number last-number
          class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import { exportGeneral } from '@/api/routes'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'


import {
  BAvatarGroup, BCard, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BFormCheckbox, VBPopover, BPopover, BFormSelect, VBTooltip, BCardHeader, BCardTitle, BBadge, BDropdown, BDropdownItem, BPagination, BSkeletonTable,
  VBToggle, 
} from 'bootstrap-vue'
export default {
  name: "TableList",
  components: {
    BAvatarGroup, BCard, BCardBody, VBTooltip, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BFormCheckbox, BPopover, BFormSelect, BCardHeader, BCardTitle, BBadge, BDropdown, BDropdownItem, BPagination, BSkeletonTable,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple
  },
  setup() {
    return {
      avatarText
    }
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    ability: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    canDownload: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    canRecover: {
      type: Boolean,
      required: false,
      default: true,
    },
    canSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    can_create_new_dreamers:{
      type: Boolean,
      required: false,
      default: false,
    },
    loading_data: {
      type: Boolean,
      required: false,
      default: false
    },
    fetch_message: {
      type: String,
      required: false,
      default: '',
    },
    school_id: {
      type: [Number, String],
      required: false,
      default: ''
    },
    buttonsOptions: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      perPage: 10,
      currentPage: 1,

      isSidebarVisible: false,


      //users_length: null, 
      customMinHeight: 500,


      selected: [],
      allSelected: false,
      checkboxIsDisabled: false
    }
  },
  watch: {
    data() {
      var countActives = this.data.filter(function (element) {
        return !element.deleted_at;
      }).length;
      if (countActives == 0) {
        this.checkboxIsDisabled = true
      } else {
        this.checkboxIsDisabled = false
      }
      this.allSelected = false
    },
    currentPage() {
      this.allSelected = false
    },
    selected() {
      var countActives = this.data.filter(function (element) {
        return !element.deleted_at;
      }).length;

      if (this.perPage > countActives) {

        if (this.selected.length == countActives) {
          this.allSelected = true
        } else {
          this.allSelected = false
        }
      } else {
        if (this.selected.length == this.perPage) {
          this.allSelected = true
        } else {
          this.allSelected = false
        }
      }
      this.$emit('manage-selected-rows', this.selected)
    }
  },
  computed: {
    tableColumns() {
      let tc = []
      switch (this.link) {
        case 'school':
          tc = [
            { key: 'name', label: this.$t('fields.school'), sortable: true },
            { key: 'country', label: this.$t('fields.country'), sortable: true },
            { key: 'community', label: this.$t('fields.community'), sortable: true },
            { key: 'payment_method_id', label: this.$t('fields.payment_method_id'), sortable: true },
            { key: 'actions', label: this.$t('fields.action') },
          ]
          break;
        case 'partners':
          tc = [
            { key: 'name', label: 'Partner', sortable: true },
            { key: 'total_licenses', label: this.$t('dash.cards.total_licences'), sortable: true },
            { key: 'support_email', label: this.$t('fields.support_email'), sortable: true },
            { key: 'actions', label: this.$t('fields.action') },
          ]
          break;
        case 'school-groups':
          tc = [
            { key: 'name', label: this.$t('fields.group'), sortable: true },
            { key: 'dreamers', label: this.$t('Dreamers'), sortable: false },
            { key: 'created_at', label: this.$t('fields.created_at'), sortable: true },
            { key: 'actions', label: this.$t('fields.action') },
          ]
          break;
        case 'group':
          tc = [
            { key: 'name', label: this.$t('fields.group'), sortable: true },
            { key: 'owner', label: this.$t('fields.owner'), sortable: true },
            { key: 'linked', label: this.$t('Dreamers'), sortable: false },
            { key: 'created_at', label: this.$t('fields.created_at'), sortable: true },
            { key: 'custom_actions', label: this.$t('fields.action') },
          ]
          break;
        case 'dreamer':
          tc = [
            { key: 'name', label: this.$t('models.dreamer'), sortable: true },
            { key: 'owner', label: this.$t('fields.owner'), sortable: true },
            { key: 'age', label: this.$t('fields.age'), sortable: true },
            { key: 'birthdate', label: this.$t('fields.f_nac'), sortable: false },
            { key: 'learning_paths', label: this.$t('dash.statistics.learning_paths') },
            { key: 'pin', label: this.$t('fields.pin'), sortable: false },
            { key: 'linked', label: this.$t('fields.group'), sortable: false },
            { key: 'created_at', label: this.$t('fields.created_at'), sortable: true },
            { key: 'custom_actions', label: this.$t('fields.action') },
          ]
          break;
        case 'school-dreamers':
          tc = [
            { key: 'name', label: this.$t('models.dreamer'), sortable: true },
            { key: 'age', label: this.$t('fields.age'), sortable: true },
            { key: 'birthdate', label: this.$t('fields.f_nac'), sortable: false },
            { key: 'learning_paths', label: this.$t('dash.statistics.learning_paths') },
            { key: 'pin', label: this.$t('fields.pin'), sortable: false },
            { key: 'groups', label: this.$t('fields.group'), sortable: false },
            { key: 'created_at', label: this.$t('fields.created_at'), sortable: true },
            /* { key: 'status', label:this.$t('fields.status'), sortable: false }, */
            { key: 'actions', label: this.$t('fields.action') },
          ]
          break;
        case 'students':
          tc = [
            { key: 'name', label: this.$t('fields.student'), sortable: true },
            { key: 'email', label: this.$t('fields.email'), sortable: true },
            { key: 'library_version_code', label: this.$t('dash.cards.version'), sortable: false },
            { key: 'status', label: this.$t('fields.status'), sortable: false },
            { key: 'subscription', label: this.$t('subscription'), sortable: true },
            { key: 'actions', label: this.$t('fields.action') },
          ]
          break;
        case 'report':
          tc = [
            { key: 'document_name', label: this.$t('reports.documents.document_name'), sortable: true },
            { key: 'document_state', label: this.$t('reports.documents.document_state'), sortable: false },
            { key: 'created_at', label: this.$t('reports.documents.creation_date'), sortable: true },
            { key: 'end_date', label: this.$t('reports.documents.end_date'), sortable: true },
            { key: 'document_actions', label: this.$t('reports.documents.actions') },
          ]
          break;
        case 'school-teachers':
        tc = [
            { key: 'name', label: this.$t('fields.name'), sortable: true },
            { key: 'email', label: this.$t('fields.email'), sortable: true },
            { key: 'library_version_code', label: this.$t('dash.cards.version'), sortable: false },
            { key: 'status', label: this.$t('fields.status'), sortable: false },
            { key: 'subscription', label: this.$t('subscription'), sortable: true },
            { key: 'actions', label: this.$t('fields.action') },
          ]
          break;
        //licences & school-teachers
        default:
          tc = [
            { key: 'name', label: this.$t('fields.name'), sortable: true },
            { key: 'email', label: this.$t('fields.email'), sortable: true },
            { key: 'library_version_code', label: this.$t('dash.cards.version'), sortable: false },
            { key: 'role', label: this.$t('fields.role'), sortable: false },
            { key: 'status', label: this.$t('fields.status'), sortable: false },
            { key: 'subscription', label: this.$t('subscription'), sortable: true },
            { key: 'actions', label: this.$t('fields.action') },
          ]
          break;
      }
      return tc
    },
    dataMeta() {
      let total = this.data.length
      let ini = total >= 1 ? 1 : 0;
      let res = (total % this.perPage)
      let total_pages = Math.floor(total / this.perPage) + 1

      let localItemsCount = this.perPage;
      if (this.currentPage == total_pages) {
        localItemsCount = this.perPage + res
      }
      return {
        from: this.perPage * (this.currentPage - 1) + (ini ? 1 : 0),
        to: this.currentPage == total_pages ? total : this.perPage * (this.currentPage - 1) + (ini ? localItemsCount : 0),
        of: total,
      }
    },
  },
  methods: {
    itemIsActive(item){
      if(item == 'deleted') return "fields.deleted"
      if(item == 'in_use') return "fields.in_use"
      if (item == 'expired') return "fields.expired"
      if (item == 'no_payment') return "fields.no_payment"
    },
    itemIsActiveColor(item){
      if(item == 'deleted') return "light-danger"
      if(item == 'in_use') return "light-success"
      if (item == 'expired') return "light-danger"
      if (item == 'no_payment') return "light-warning"
    },
    imageTooltip(pinArray) {
      return pinArray.map(pin => `<img src="${require('@/assets/images/passwords/' + pin + '.png')}" style="width: 45px; height: 45px;" />`).join('');
    },
    async download() {
      let column_headers = []
      column_headers.push('id')
      this.tableColumns.forEach(element => {
        element.key != 'actions' ? column_headers.push(element.key) : null
      });
      let rows_ids = []
      this.data.map(x => {
        rows_ids.push(x.id)
      })
      let data = {
        rows_ids: rows_ids,
        context: this.link.toLowerCase(),
      }

      await this.$http.post(exportGeneral, data, { responseType: 'blob' }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.link + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      })

    },
    resolveAvatar(item) {
      let avatar = ''
      switch (this.link) {
        case 'partners':
        case 'school':
          avatar = item.logo_url
          break;
        case 'group':
        case 'school-groups':
          if (item.id == 0) {
            avatar = require('@/assets/images/avatar/groups/null.png?v4')
          } else {
            avatar = require('@/assets/images/avatar/groups/' + (item.id_avatar || item.avatar || 'null') + '.png?v4')
          }
          break;
        case 'dreamer':
        case 'school-dreamers':
          avatar = require('@/assets/images/avatar/AvatarSprite_' + (item.avatar || '57') + '.png?v4')
          break;

        default:
          avatar = item.avatar
          break;
      }
      return avatar
    },
    link_params(data) {
      let params = {}
      switch (this.link) {
        case 'school-groups':
          params = {
            id: this.school_id,
            group_id: data.item.id
          }
          break;
        case 'school-dreamers':
          params = {
            id: this.school_id,
            dreamer_id: data.item.id
          }
          break;
        case 'students':
          params = {
            id: this.school_id,
            student_id: data.item.id
          }
          break;
        case 'school-teachers':
          params = {
            id: this.school_id,
            teacher_id: data.item.id
          }
          break;
        default:
          params = { id: data.item.id }
          break;
      }
      return params
    },
    manageSelectAll(val) {
      if (val) {
        this.data.forEach((element, i) => {
          if (!element.deleted_at) {
            this.$refs.refUserListTable.selectRow(i)
          }
        });
      } else {
        this.$refs.refUserListTable.clearSelected()
      }
    },
    manageRowSelection(index, value) {
      if (value) {
        this.$refs.refUserListTable.selectRow(index)
      } else {
        this.$refs.refUserListTable.unselectRow(index)
      }
    },
    onRowSelected(items) {
      this.selected = items //Array de objetos seleccionados
    },
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/app-learning-paths.scss";
 #sidebar-right{
  width: 40vw;
 }
  .custom-min-height {
    min-height: 300px; 
  }
  .icon-container {
  width: 35px;
  height: 35px;
  background-color: #e6f0fa;
}
.align-self-center {
  display: flex;
  flex-direction: row;
  min-width: 105px; 
}
</style>